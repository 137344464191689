import { gql } from '@apollo/client';
import { WordPressBlocksViewer } from '@faustwp/blocks';
import { FaustTemplate, flatListToHierarchical } from '@faustwp/core';
import dynamic from 'next/dynamic';
import { GetPageQuery } from '../__generated__/graphql';
import {
	AcfPostFeedFragment,
	AcfAccordionDisplayFragment,
	AcfCountUpDisplayFragment,
	AcfHeroBannerFragment,
	AcfLogoSliderFragment,
	AcfProductIconDisplayFragment,
	AcfColorIconDisplayFragment,
	AcfStepDisplayFragment,
	CoreButtonBlockFragment,
	CoreButtonsBlockFragment,
	CoreColumnBlockFragments,
	CoreColumnsBlockFragment,
	CoreGroupBlockFragment,
	CoreImageBlockFragment,
	CoreParagraphFragment,
	GravityformsFormFragment,
} from '../wp-blocks/fragments';

// Dynamically import the SiteWrapper component
const SiteWrapper = dynamic(() => import('../components/SiteWrapper'), {
	ssr: false, // Set to false if the component should not be server-side rendered
});

const Page: FaustTemplate<GetPageQuery> = props => {
	if (props.loading) {
		return <>Loading page data...</>;
	}

	if (!props.data) {
		console.error('props.data is undefined');
		return <>Error: Data is missing</>;
	}

	if (!props.data.page || !props.data.page.editorBlocks) {
		return <>Error: Page data is missing or undefined</>;
	}

	const { editorBlocks } = props.data.page;
	const hierarchicalBlocks = flatListToHierarchical(editorBlocks, {
		childrenKey: 'innerBlocks',
	});

	return (
		<SiteWrapper siteProps={props.data}>
			<WordPressBlocksViewer blocks={hierarchicalBlocks} />
		</SiteWrapper>
	);
};

Page.query = gql`
	${CoreParagraphFragment}
	${CoreGroupBlockFragment}
	${CoreColumnsBlockFragment}
	${CoreColumnBlockFragments}
	${CoreImageBlockFragment}
	${CoreButtonsBlockFragment}
	${CoreButtonBlockFragment}

	query GetPage($databaseId: ID!, $asPreview: Boolean = false) {
		page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
			title
			editorBlocks(flat: true) {
				name
				__typename
				renderedHtml
				id: clientId
				parentId: parentClientId
				...CoreParagraphFragment
				...CoreGroupBlockFragment
				...CoreColumnsBlockFragment
				...CoreColumnBlockFragments
				...CoreImageBlockFragment
				...CoreButtonsBlockFragment
				...CoreButtonBlockFragment
				${AcfPostFeedFragment}
				${AcfHeroBannerFragment}
				${AcfStepDisplayFragment}
				${AcfCountUpDisplayFragment}
				${AcfLogoSliderFragment}
				${AcfAccordionDisplayFragment}
				${AcfProductIconDisplayFragment}
				${AcfColorIconDisplayFragment}
				${GravityformsFormFragment}
			}
			seo {
				canonical
				title
				metaDesc
				focuskw
				schema {
					pageType
					articleType
					raw
				}
			}
			saswpCustomSchema {
				json_ld
			}
			pageSettings {
				isLandingPage
				ctaTextOverride
				ctaUrlOverride
				customLogo {
					node {
						altText
						sizes
						sourceUrl
						mediaDetails {
							height
							width
						}
						srcSet(size: _2048X2048)
					}
				}
			}
		}
		generalSettings {
			title
			description
		}
		primaryMenuItems: menuItems(where: { location: PRIMARY }, last: 50) {
			nodes {
				id
				uri
				path
				label
				parentId
				cssClasses
				url
			}
		}
		footerMenuItems: menuItems(where: { location: FOOTER }) {
			nodes {
				id
				uri
				path
				label
				parentId
				cssClasses
				menu {
					node {
						name
					}
				}
			}
		}
		productMenuItems: menuItems(where: { location: PRODUCT }) {
			nodes {
				id
				uri
				path
				label
				parentId
				cssClasses
				menu {
					node {
						name
					}
				}
				url
			}
		}
		cbaSiteSettings {
			cbaSiteSettingsFields {
				topText
				ctaText
				ctaHeader
				ctaContent
				ctaButtonLink {
					nodes {
						uri
						link
					}
				}
				ctaButtonText
				productMenuTitle
				phoneNumber
				address
				ctaLink {
					nodes {
						uri
						link
					}
				}
				socialMedia {
					link
					platform
				}
        copyright
			}
		}
	}
`;

Page.variables = ({ databaseId }, ctx) => {
	return {
		databaseId,
		asPreview: ctx?.asPreview,
	};
};

export default Page;
