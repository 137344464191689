import { gql } from '@apollo/client';
import { Stack } from '@chakra-ui/react';
import {
	WordPressBlock,
	WordPressBlocksViewer,
	getStyles,
	useBlocksTheme,
} from '@faustwp/blocks';

import { ContentBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksViewer';
import { propsObjectType } from '../../types/declarations';

export type CoreColumnsFragmentProps = ContentBlock & {
	attributes?: {
		cssClassName?: string;
		align?: string;
		anchor?: string;
		layout?: string;
		isStackedOnMobile?: boolean;
		verticalAlignment?: string;
		borderColor?: string;
		backgroundColor?: string;
		fontSize?: string;
		fontFamily?: string;
		style?: string;
		textColor?: string;
		gradient?: string;
	};
};
interface GapSizes {
	[key: number]: number;
}
const gapSizes: GapSizes = {
	30: 12,
	40: 14,
	50: 16,
};

const CoreColumns: WordPressBlock<CoreColumnsFragmentProps> = props => {
	const theme = useBlocksTheme();
	const style = getStyles(theme, { ...props });
	const { attributes, innerBlocks } = props;

	const styleAttribute = attributes.style
		? JSON.parse(props.attributes.style)
		: { type: 'default' };

	const additionalProps: propsObjectType = {};

	// console.log('Columns Props', props, styleAttribute);
	if (
		styleAttribute?.spacing?.blockGap &&
		styleAttribute.spacing.blockGap?.left
	) {
		// Get only numbers from string styleAttribute?.spacing?.blockGap
		const blockGap = styleAttribute.spacing.blockGap?.left.replace(
			/\D/g,
			''
		);

		additionalProps.gap = {
			base: 4,
			md: blockGap in gapSizes ? gapSizes[blockGap] : (blockGap / 10) * 2,
		};
	}

	return (
		<Stack
			style={style}
			className={attributes?.cssClassName}
			direction={{
				base: 'column',
				md: 'row',
			}}
			{...additionalProps}
		>
			<WordPressBlocksViewer blocks={innerBlocks ?? []} />
		</Stack>
	);
};

CoreColumns.fragments = {
	key: `CoreColumnsBlockFragment`,
	entry: gql`
		fragment CoreColumnsBlockFragment on CoreColumns {
			attributes {
				align
				anchor
				layout
				cssClassName
				isStackedOnMobile
				verticalAlignment
				borderColor
				backgroundColor
				fontSize
				fontFamily
				style
				textColor
				gradient
			}
		}
	`,
};
CoreColumns.config = {
	name: 'CoreColumns',
};
CoreColumns.displayName = 'CoreColumns';

export default CoreColumns;
