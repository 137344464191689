import { WordPressBlock } from '@faustwp/blocks';
import { AcfColorIconDisplay as AcfColorIconGeneratedProps } from '../../__generated__/graphql';
import ColorIconDisplay from '../../components/ColorIconDisplay';
import { AcfColorIconDisplayProps } from '../../types/AcfColorIconDisplayProps';

const AcfColorIconDisplay: WordPressBlock<
	AcfColorIconGeneratedProps
> = props => {
	const image = props.blockColorIconDisplay.icon;

	const productIconProps: AcfColorIconDisplayProps = {
		title: props.blockColorIconDisplay.title,
		icon: {
			sourceUrl: image?.node?.sourceUrl,
			sizes: image?.node.sizes,
			altText: image?.node.altText,
			srcSet: image?.node.srcSet,
			mediaDetails: {
				width: image?.node.mediaDetails.width,
				height: image?.node.mediaDetails.height,
			},
		},
		content: props.blockColorIconDisplay.content,
		color: props.blockColorIconDisplay.color,
	};
	return <ColorIconDisplay {...productIconProps} />;
};

AcfColorIconDisplay.displayName = 'AcfColorIconDisplay';

export default AcfColorIconDisplay;
