import { create } from 'zustand';
import { GetPageQuery } from '../__generated__/graphql';
import { buildMenuTree } from '../support/buildMenuTree';
import { FooterCtaProps } from '../types/FooterSettings';
import { GfFormProps } from '../types/GravityForms';
import { ImageProps } from '../types/Image';
import { MenuItemProps } from '../types/Menu';

export type SiteState = {
	menuItems: MenuItemProps[];
	footerMenuItems: GetPageQuery['footerMenuItems']['nodes'];
	seo: GetPageQuery['page']['seo'];
	customSchema: GetPageQuery['page']['saswpCustomSchema'];
	phoneNumber: string;
	address: string;
	ctaText: string;
	ctaUrl: string;
	footerCta: FooterCtaProps;
	gform: GfFormProps;
	pageTitle: string;
	topText: string;
	socialMedia: GetPageQuery['cbaSiteSettings']['cbaSiteSettingsFields']['socialMedia'];
	isLandingPage: boolean;
	customLogo: any;
};

export type SiteActions = {
	setMenuItems: (
		menuItems: GetPageQuery['primaryMenuItems']['nodes']
	) => void;
	setFooterMenuItems: (
		footerMenuItems: GetPageQuery['footerMenuItems']['nodes']
	) => void;
	setSeo: (seo: GetPageQuery['page']['seo']) => void;
	setSchema: (
		customSchema: GetPageQuery['page']['saswpCustomSchema']
	) => void;
	setFooterCta: (footerCta: FooterCtaProps) => void;
	setSitePhoneNumber: (val: string) => void;
	setSiteAddress: (val: string) => void;
	setCta: (text: string, url: string) => void;
	setGform: (data: GfFormProps) => void;
	setPageTitle: (title: string) => void;
	setTopText: (text: string) => void;
	setSocialMedia: (
		socialMedia: GetPageQuery['cbaSiteSettings']['cbaSiteSettingsFields']['socialMedia']
	) => void;
	getSitePhoneNumber: () => string;
	setIsLandingPage: (isLandingPage: boolean) => void;
	setCustomLogo: (customLogo: any) => void;
};

export const useSiteStore = create<SiteState & SiteActions>(set => ({
	menuItems: [],

	footerMenuItems: [],

	seo: {} as GetPageQuery['page']['seo'],

	customSchema: {} as GetPageQuery['page']['saswpCustomSchema'],

	phoneNumber: '',

	pageTitle: '',

	address: '',

	ctaText: '',

	ctaUrl: '',

	footerCta: {} as FooterCtaProps,

	gform: {} as GfFormProps,

	topText: '',

	socialMedia:
		{} as GetPageQuery['cbaSiteSettings']['cbaSiteSettingsFields']['socialMedia'],

	isLandingPage: false,

	customLogo: {} as ImageProps,

	setMenuItems: menuItems => {
		const tree = buildMenuTree(menuItems);
		set({ menuItems: tree });
	},

	setFooterMenuItems: footerMenuItems => set({ footerMenuItems }),

	setFooterCta: footerCta => set({ footerCta }),

	setSitePhoneNumber: (val: string) => set({ phoneNumber: val }),

	setSiteAddress: (val: string) => set({ address: val }),

	setSeo: (seo: GetPageQuery['page']['seo']) => set({ seo }),

	setSchema: customSchema => set({ customSchema }),

	setCta: (text, url) => {
		set({ ctaText: text });
		set({ ctaUrl: url });
	},

	setGform: data => set({ gform: data }),

	setPageTitle: title => set({ pageTitle: title }),

	setTopText: text => set({ topText: text }),

	setSocialMedia: socialMedia => set({ socialMedia }),

	getSitePhoneNumber: () => {
		// Check if the window object is available (client-side)
		if (typeof window === 'undefined') {
			return '';
		}

		const mainPhone = document.getElementById('main-phone');

		if (!mainPhone) {
			return '';
		}

		return mainPhone.textContent || '';
	},
	setIsLandingPage: isLandingPage => set({ isLandingPage }),

	setCustomLogo: customLogo => set({ customLogo }),
}));
