import Head from 'next/head';
import React, { FC } from 'react';
import { useSiteStore } from '../../hooks/use-site-store';
import { updateUrls } from '../../support/helpers';

type SiteHeadProps = {
	description?: string;
};

const SiteHead: FC<SiteHeadProps> = ({ description }) => {
	const { seo, customSchema } = useSiteStore(state => state);

	const { title, canonical, metaDesc, schema } = seo;

	const finalDescription = metaDesc ? metaDesc : description;

	let pageSchema = null;

	if (schema?.raw) {
		try {
			pageSchema = JSON.parse(schema.raw);
			updateUrls(
				pageSchema,
				process.env.NEXT_PUBLIC_WORDPRESS_URL,
				`${process.env.NEXT_PUBLIC_FRONTEND_URL}/`
			);
		} catch (error) {
			console.error('Failed to parse schema:', error);
		}
	}

	return (
		<Head>
			<meta property="og:type" content="website" />
			<meta property="twitter:card" content="summary_large_image" />
			<link rel="icon" href="/favicon.ico" sizes="any" />
			<link
				rel="preconnect"
				href={process.env.NEXT_PUBLIC_WORDPRESS_URL}
			/>
			{title && (
				<>
					<title>{title}</title>
					<meta name="title" content={title} />
					<meta property="og:title" content={title} />
					<meta property="twitter:title" content={title} />
				</>
			)}
			{finalDescription && (
				<>
					<meta name="description" content={finalDescription} />
					<meta
						property="og:description"
						content={finalDescription}
					/>
					<meta
						property="twitter:description"
						content={finalDescription}
					/>
				</>
			)}
			{canonical && (
				<link
					rel="canonical"
					href={canonical.replace(
						process.env.NEXT_PUBLIC_WORDPRESS_URL,
						`${process.env.NEXT_PUBLIC_FRONTEND_URL}/`
					)}
				></link>
			)}
			{pageSchema && (
				<script type="application/ld+json">
					{JSON.stringify(pageSchema, null, 2)}
				</script>
			)}
			{customSchema &&
				customSchema.json_ld &&
				(() => {
					try {
						const parsedSchema = JSON.parse(customSchema.json_ld);
						return (
							<script type="application/ld+json">
								{JSON.stringify(parsedSchema, null, 2)}
							</script>
						);
					} catch (error) {
						console.error(
							'Invalid JSON in customSchema.json_ld:',
							error
						);
						return null; // Or handle the error gracefully
					}
				})()}
		</Head>
	);
};

export default SiteHead;
