import { WordPressBlock } from '@faustwp/blocks';
import { Box, SimpleGrid } from '@chakra-ui/react';
import PostCard from '../../components/PostFeed/components/PostCard';
import { BlockPostFeed, GetArchiveQuery } from '../../__generated__/graphql';

type AcfPostFeedProps = {
	blockPostFeed: {
		posts: {
			nodes: {
				posts: {
					nodes: GetArchiveQuery['posts']['nodes'];
				};
			}[];
		};
	};
};

const AcfPostFeed: WordPressBlock<AcfPostFeedProps> = props => {
	return (
		<>
			{props.blockPostFeed.posts.nodes.map((category, index) => (
				<Box key={index}>
					<SimpleGrid
						columns={{ base: 2, lg: 3 }}
						gap={{
							base: 4,
							lg: 8,
						}}
					>
						{category.posts.nodes.map((post, i) => (
							<PostCard key={i} post={post} />
						))}
					</SimpleGrid>
				</Box>
			))}
		</>
	);
};

AcfPostFeed.displayName = 'AcfPostFeed';

export default AcfPostFeed;
