import { Box, ChakraBaseProvider } from '@chakra-ui/react';
import { WordPressBlocksProvider, fromThemeJson } from '@faustwp/blocks';
import { WordPressBlocksProviderConfig } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksProvider';
import { FaustProvider } from '@faustwp/core';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import '../../faust.config';
import themeJson from '../../theme.json';
import theme from '../theme';
import wpBlocks from '../wp-blocks';
import '../../globalStylesheet.css';
import '../styles/globals.scss';
import { noto_sans, teko } from '../fonts';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

export default function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter();

	const config: WordPressBlocksProviderConfig = {
		blocks: wpBlocks,
		theme: fromThemeJson(themeJson),
	};

	useEffect(() => {
		const gtmTag = process.env.NEXT_PUBLIC_GTM_CONTAINER;
		const siteUrl = process.env.NEXT_PUBLIC_FRONTEND_URL;

		if ('http://localhost:3000' !== siteUrl) {
			TagManager.initialize({
				gtmId: gtmTag,
			});
		}
	}, []);

	return (
		<FaustProvider pageProps={pageProps}>
			<WordPressBlocksProvider config={config}>
				<ChakraBaseProvider theme={theme}>
					<Box
						className={`${noto_sans.variable} ${teko.variable}`}
						sx={{
							'h1,h2,h3,h4,h5': {
								fontFamily: noto_sans.style.fontFamily,
							},
						}}
					>
						<Component {...pageProps} key={router.asPath} />
					</Box>
				</ChakraBaseProvider>
			</WordPressBlocksProvider>
		</FaustProvider>
	);
}
