import { gql } from '@apollo/client';

type FieldProps = {
	TextField: string;
	TextAreaField: string;
	PhoneField: string;
	EmailField: string;
	SelectField: string;
	DateField: string;
};

const baseFields = `
  id
  label
  description
  errorMessage
  isRequired
  placeholder
  visibility
  value
  cssClass
  layoutGridColumnSpan
  labelPlacement
`;

const fieldProps = {
	TextField: `
    ${baseFields}
    inputName
    defaultValue
    maxLength
    size
  `,
	TextAreaField: `
    ${baseFields}
    inputName
    defaultValue
    size
  `,
	PhoneField: `
    size
    ${baseFields}
  `,
	EmailField: `
    size
    ${baseFields}
  `,
	SelectField: `
    size
    ${baseFields}
    defaultValue
    choices {
      text
      value
    }
  `,
	DateField: `
    ${baseFields}
  `,
};

/**
 * Partial: retrieve basic data on all form fields.
 *
 * @author WebDevStudios
 * @return {string} Form fields query partial.
 */
function getFormFieldsPartial(): string {
	return (
		Object.keys(fieldProps)
			// Build individual query partials by field type.
			.map(field => {
				// Type assertion to make sure 'field' is a valid key of FieldProps.
				const key = field as keyof FieldProps;
				return `
          ... on ${field} {
            ${fieldProps[key]}
          }
        `;
			})
			// Connect query partial pieces.
			.join('')
	);
}

export const GFORM_QUERY = gql`
	query GformQuery($gformId: ID!) {
		gfForm(id: $gformId, idType: DATABASE_ID) {
			databaseId
			cssClass
			description
			submitButton {
				text
				layoutGridColumnSpan
				location
				type
				width
			}
			formFields(where: { pageNumber: 1 }) {
				nodes {
					databaseId
					inputType
					type
					layoutGridColumnSpan
					visibility
					... on HtmlField {
						content
						databaseId
					}
					... on GfFieldWithLabelSetting {
						label
					}
					... on GfFieldWithRulesSetting {
						isRequired
					}
					... on GfFieldWithAddressSetting {
						defaultState
						defaultCountry
						defaultProvince
						addressType
					}
					... on GfFieldWithInputs {
						inputs {
							id
							label
							... on AddressInputProperty {
								autocompleteAttribute
								customLabel
								placeholder
							}
						}
					}
					... on GfFieldWithChoices {
						choices {
							text
							value
							... on GfFieldChoiceWithChoicesSetting {
								isSelected
							}
						}
					}
					... on TextField {
						id
						databaseId
						isRequired
						placeholder
						labelPlacement
					}
					... on CheckboxField {
						id
						databaseId
						labelPlacement
					}
					... on ConsentField {
						id
						databaseId
						checkboxLabel
						consentValue
						description
						labelPlacement
					}
					... on PhoneField {
						id
						databaseId
						isRequired
						placeholder
						labelPlacement
					}
					... on EmailField {
						id
						databaseId
						isRequired
						placeholder
						labelPlacement
					}
				}
			}
		}
	}
`;
