import { Box, Image } from '@chakra-ui/react';
import { FC } from 'react';
import { AcfColorIconDisplayProps } from '../../types/AcfColorIconDisplayProps';

const ColorIconDisplay: FC<AcfColorIconDisplayProps> = props => {
	return (
		<Box
			role="group"
			border={`2px solid ${props.color}`}
			borderRadius="lg"
			textAlign="center"
		>
			{props.icon && (
				<Box
					bg={props.color}
					py={3}
					px={4}
					mx="auto"
					maxW="160px"
					textAlign="center"
					borderBottomRadius="lg"
				>
					<Image
						src={props.icon.sourceUrl}
						alt={props.icon.altText}
						width={70}
						height={70}
						display="block"
						mx="auto"
					/>
				</Box>
			)}
			<Box px={4} pb={4}>
				<Box
					color={props.color}
					fontWeight="bold"
					fontSize="lg"
					px={10}
					lineHeight="1.2"
					my={4}
				>
					{props.title}
				</Box>
				<Box
					px={4}
					dangerouslySetInnerHTML={{ __html: props.content }}
				/>
			</Box>
		</Box>
	);
};

export default ColorIconDisplay;
